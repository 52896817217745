<template>
  <div v-if="boolCarregado">
    <ed-filter
      :key="'table-filter-' + $root.$session.versao"
      :filter="formFiltros"
      @filter="getMovimento"
    >
      <template slot="table-filter">
        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          label="Data Competência"
          name="arrayData"
          v-model="formFiltros.arrayDataCadastro"
          multiple
          clearable
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formFiltros.intDestinoId"
          label="Cliente/Fornecedor"
          name="intDestinoId"
          item-text="strNome"
          route="Cadastro/CadastroGeral"
          :filters="{}"
          clearable
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formFiltros.arrayParametroStatusReceita"
          label="Status Receitas"
          name="arrayParametroStatusReceita"
          item-text="strNome"
          route="Common/Parametro"
          :filters="{
            boolBuscarSomenteAtivo: true,
            boolBuscarParaLista: true,
            strChave: 'financeiroStatus',
          }"
          clearable
          multiple
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formFiltros.arrayParametroStatusDespesa"
          label="Status Despesa"
          name="arrayParametroStatusDespesa"
          item-text="strNome"
          route="Common/Parametro"
          :filters="{
            boolBuscarSomenteAtivo: true,
            boolBuscarParaLista: true,
            strChave: 'financeiroStatus',
          }"
          clearable
          multiple
        />

        <ed-input-auto-complete
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formFiltros.arrayParametroFormaPagamento"
          label="Forma de Pagamento"
          name="arrayParametroFormaPagamento"
          item-text="strNome"
          route="Common/Parametro"
          :filters="{
            boolBuscarSomenteAtivo: true,
            boolBuscarParaLista: true,
            strChave: 'financeiroFormaPagamento',
          }"
          clearable
          multiple
        />

        <ed-input-auto-complete
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formFiltros.intContaBancariaId"
          label="Conta"
          name="intContaBancariaId"
          item-text="strNome"
          route="Configuracoes/ContaBancaria"
          :filters="{}"
          clearable
        />

        <ed-input-select
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formFiltros.strAgrupar"
          label="Tipo de Fluxo de Caixa"
          name="strAgrupar"
          item-text="strNome"
          :items="[
            { intId: 'ano', strNome: 'Ano' },
            { intId: 'mes', strNome: 'Mês' },
            { intId: 'semana', strNome: 'Semana' },
            { intId: 'dia', strNome: 'Dia' },
          ]"
          clearable
        />

        <ed-input-auto-complete
            v-if="$root.$session.filiais.length > 1"
            class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            v-model="formFiltros.arrayFilial"
            label="Filial"
            name="arrayFilial"
            item-text="strNome"
            route="Cadastro/Filial"
            :filters="{
            }"
            clearable
            multiple
          />
      </template>
    </ed-filter>

    <div class="row mb-3" v-if="formData">
      <ed-simple-table disabledSearchRows disabledRegisterRows>
        <thead>
          <tr>
            <th></th>
            <th
              width="150"
              v-for="(strCompetencia, index) in formData.coluna"
              :key="'competencia-' + index"
            >
              {{ strCompetencia }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!--RECEITAS-->
          <template v-if="formData.categoria && formData.categoria.tipo_movimento_1443 && formData.categoria.tipo_movimento_1443._totalizador_">
            <tr style="background: #d2eace">
              <td>TOTAL DE RECEITAS</td>
              <td
                v-for="(objDados, strCompetencia) in formData.categoria
                  .tipo_movimento_1443._totalizador_"
                :key="'receitas-competencia-' + strCompetencia"
              >
                {{ $utilidade.floatToMoney(objDados.floatValor) }}
              </td>
            </tr>
            <template
              v-for="(arrayDados, strCategoria) in formData.categoria
                .tipo_movimento_1443"
            >
              <tr
                :key="'receitas-' + strCategoria"
                v-if="strCategoria != '_totalizador_'"
              >
                <td>{{ strCategoria }}</td>
                <td
                  v-for="(objDados, strCompetencia) in arrayDados"
                  :key="'receitas-competencia-' + strCompetencia"
                >
                  {{ $utilidade.floatToMoney(objDados.floatValor) }}
                </td>
              </tr>
            </template>
          </template>

          <!--DESPESAS-->
          <template v-if="formData.categoria && formData.categoria.tipo_movimento_1444 && formData.categoria.tipo_movimento_1444._totalizador_">
            <tr style="background: #e8c5c5">
              <td>TOTAL DE DESPESAS</td>
              <td
                v-for="(objDados, strCompetencia) in formData.categoria
                  .tipo_movimento_1444._totalizador_"
                :key="'receitas-competencia-' + strCompetencia"
              >
                {{ $utilidade.floatToMoney(objDados.floatValor) }}
              </td>
            </tr>
            <template
              v-for="(arrayDados, strCategoria) in formData.categoria
                .tipo_movimento_1444"
            >
              <tr
                :key="'receitas-' + strCategoria"
                v-if="strCategoria != '_totalizador_'"
              >
                <td>{{ strCategoria }}</td>
                <td
                  v-for="(objDados, strCompetencia) in arrayDados"
                  :key="'receitas-competencia-' + strCompetencia"
                >
                  {{ $utilidade.floatToMoney(objDados.floatValor) }}
                </td>
              </tr>
            </template>
          </template>

          <!--SALDO-->
          <tr style="background: #eeeeee; font-weight: bold">
            <td>SALDO FINAL</td>
            <td
              v-for="(objDados, strCompetencia) in formData.saldo"
              :key="'saldo-' + strCompetencia"
              :style="'color:' + (objDados.floatValor < 0 ? 'red' : 'green')"
            >
              {{ $utilidade.floatToMoney(objDados.floatValor) }}
            </td>
          </tr>
        </tbody>
      </ed-simple-table>
    </div>
  </div>
</template>

<script>
import {
  EdFilter,
  EdModal,
  EdInputAutoComplete,
  EdInputSelect,
  EdInputDate,
  EdButton,
  EdInputText,
  EdSimpleTable,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {
    EdFilter,
    EdModal,
    EdInputAutoComplete,
    EdInputSelect,
    EdInputDate,
    EdButton,
    EdInputText,
    EdSimpleTable,
  },
  props: {
    intTipoMovimentoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  watch: {
    intTipoMovimentoId() {},
  },
  mounted() {
    this.getMovimento();
    this.boolCarregado = true;
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      boolCarregado: false,
      formFiltros: {
        boolGerarCaixa: true,
        strAgrupar: "mes",
        intDestinoId: null,
        intContaBancariaId: null,
        arrayParametroStatusDespesa: [],
        arrayParametroStatusReceita:[],
        arrayParametroFormaPagamento: [],
        arrayFilial:[],
        arrayDataCadastro: [
          this.$utilidade.getInicioMes('DD/MM/YYYY',this.$utilidade.addDate(-1, null, "M", "YYYY-MM-DD"))
          ,
          this.$utilidade.getFimMes('DD/MM/YYYY',this.$utilidade.addDate(-1, null, "M", "YYYY-MM-DD"))
        ],
      },
      formData: null,
    };
  },
  methods: {
    exportar() {
      let formFiltros = _.cloneDeep(this.formFiltros);
      formFiltros.boolExportar = true;
    },

    getMovimento() {
      this.$root.$dialog.loading(true);

      this.formData = null;

      this.$root.$request
        .get("Movimento/Movimento", this.formFiltros)
        .then((objResult) => {
          this.$root.$dialog.loading(false);

          if (objResult.status == 200) {
            this.formData = Object.assign(objResult.result);
          }
        });
    },
  },
};
</script>
