var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.boolCarregado)?_c('div',[_c('ed-filter',{key:'table-filter-' + _vm.$root.$session.versao,attrs:{"filter":_vm.formFiltros},on:{"filter":_vm.getMovimento}},[_c('template',{slot:"table-filter"},[_c('ed-input-date',{staticClass:"col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",attrs:{"label":"Data Competência","name":"arrayData","multiple":"","clearable":""},model:{value:(_vm.formFiltros.arrayDataCadastro),callback:function ($$v) {_vm.$set(_vm.formFiltros, "arrayDataCadastro", $$v)},expression:"formFiltros.arrayDataCadastro"}}),_c('ed-input-auto-complete',{staticClass:"col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",attrs:{"label":"Cliente/Fornecedor","name":"intDestinoId","item-text":"strNome","route":"Cadastro/CadastroGeral","filters":{},"clearable":""},model:{value:(_vm.formFiltros.intDestinoId),callback:function ($$v) {_vm.$set(_vm.formFiltros, "intDestinoId", $$v)},expression:"formFiltros.intDestinoId"}}),_c('ed-input-auto-complete',{staticClass:"col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",attrs:{"label":"Status Receitas","name":"arrayParametroStatusReceita","item-text":"strNome","route":"Common/Parametro","filters":{
          boolBuscarSomenteAtivo: true,
          boolBuscarParaLista: true,
          strChave: 'financeiroStatus',
        },"clearable":"","multiple":""},model:{value:(_vm.formFiltros.arrayParametroStatusReceita),callback:function ($$v) {_vm.$set(_vm.formFiltros, "arrayParametroStatusReceita", $$v)},expression:"formFiltros.arrayParametroStatusReceita"}}),_c('ed-input-auto-complete',{staticClass:"col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",attrs:{"label":"Status Despesa","name":"arrayParametroStatusDespesa","item-text":"strNome","route":"Common/Parametro","filters":{
          boolBuscarSomenteAtivo: true,
          boolBuscarParaLista: true,
          strChave: 'financeiroStatus',
        },"clearable":"","multiple":""},model:{value:(_vm.formFiltros.arrayParametroStatusDespesa),callback:function ($$v) {_vm.$set(_vm.formFiltros, "arrayParametroStatusDespesa", $$v)},expression:"formFiltros.arrayParametroStatusDespesa"}}),_c('ed-input-auto-complete',{staticClass:"col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",attrs:{"label":"Forma de Pagamento","name":"arrayParametroFormaPagamento","item-text":"strNome","route":"Common/Parametro","filters":{
          boolBuscarSomenteAtivo: true,
          boolBuscarParaLista: true,
          strChave: 'financeiroFormaPagamento',
        },"clearable":"","multiple":""},model:{value:(_vm.formFiltros.arrayParametroFormaPagamento),callback:function ($$v) {_vm.$set(_vm.formFiltros, "arrayParametroFormaPagamento", $$v)},expression:"formFiltros.arrayParametroFormaPagamento"}}),_c('ed-input-auto-complete',{staticClass:"col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",attrs:{"label":"Conta","name":"intContaBancariaId","item-text":"strNome","route":"Configuracoes/ContaBancaria","filters":{},"clearable":""},model:{value:(_vm.formFiltros.intContaBancariaId),callback:function ($$v) {_vm.$set(_vm.formFiltros, "intContaBancariaId", $$v)},expression:"formFiltros.intContaBancariaId"}}),_c('ed-input-select',{staticClass:"col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",attrs:{"label":"Tipo de Fluxo de Caixa","name":"strAgrupar","item-text":"strNome","items":[
          { intId: 'ano', strNome: 'Ano' },
          { intId: 'mes', strNome: 'Mês' },
          { intId: 'semana', strNome: 'Semana' },
          { intId: 'dia', strNome: 'Dia' } ],"clearable":""},model:{value:(_vm.formFiltros.strAgrupar),callback:function ($$v) {_vm.$set(_vm.formFiltros, "strAgrupar", $$v)},expression:"formFiltros.strAgrupar"}}),(_vm.$root.$session.filiais.length > 1)?_c('ed-input-auto-complete',{staticClass:"col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",attrs:{"label":"Filial","name":"arrayFilial","item-text":"strNome","route":"Cadastro/Filial","filters":{
          },"clearable":"","multiple":""},model:{value:(_vm.formFiltros.arrayFilial),callback:function ($$v) {_vm.$set(_vm.formFiltros, "arrayFilial", $$v)},expression:"formFiltros.arrayFilial"}}):_vm._e()],1)],2),(_vm.formData)?_c('div',{staticClass:"row mb-3"},[_c('ed-simple-table',{attrs:{"disabledSearchRows":"","disabledRegisterRows":""}},[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.formData.coluna),function(strCompetencia,index){return _c('th',{key:'competencia-' + index,attrs:{"width":"150"}},[_vm._v(" "+_vm._s(strCompetencia)+" ")])})],2)]),_c('tbody',[(_vm.formData.categoria && _vm.formData.categoria.tipo_movimento_1443 && _vm.formData.categoria.tipo_movimento_1443._totalizador_)?[_c('tr',{staticStyle:{"background":"#d2eace"}},[_c('td',[_vm._v("TOTAL DE RECEITAS")]),_vm._l((_vm.formData.categoria
                .tipo_movimento_1443._totalizador_),function(objDados,strCompetencia){return _c('td',{key:'receitas-competencia-' + strCompetencia},[_vm._v(" "+_vm._s(_vm.$utilidade.floatToMoney(objDados.floatValor))+" ")])})],2),_vm._l((_vm.formData.categoria
              .tipo_movimento_1443),function(arrayDados,strCategoria){return [(strCategoria != '_totalizador_')?_c('tr',{key:'receitas-' + strCategoria},[_c('td',[_vm._v(_vm._s(strCategoria))]),_vm._l((arrayDados),function(objDados,strCompetencia){return _c('td',{key:'receitas-competencia-' + strCompetencia},[_vm._v(" "+_vm._s(_vm.$utilidade.floatToMoney(objDados.floatValor))+" ")])})],2):_vm._e()]})]:_vm._e(),(_vm.formData.categoria && _vm.formData.categoria.tipo_movimento_1444 && _vm.formData.categoria.tipo_movimento_1444._totalizador_)?[_c('tr',{staticStyle:{"background":"#e8c5c5"}},[_c('td',[_vm._v("TOTAL DE DESPESAS")]),_vm._l((_vm.formData.categoria
                .tipo_movimento_1444._totalizador_),function(objDados,strCompetencia){return _c('td',{key:'receitas-competencia-' + strCompetencia},[_vm._v(" "+_vm._s(_vm.$utilidade.floatToMoney(objDados.floatValor))+" ")])})],2),_vm._l((_vm.formData.categoria
              .tipo_movimento_1444),function(arrayDados,strCategoria){return [(strCategoria != '_totalizador_')?_c('tr',{key:'receitas-' + strCategoria},[_c('td',[_vm._v(_vm._s(strCategoria))]),_vm._l((arrayDados),function(objDados,strCompetencia){return _c('td',{key:'receitas-competencia-' + strCompetencia},[_vm._v(" "+_vm._s(_vm.$utilidade.floatToMoney(objDados.floatValor))+" ")])})],2):_vm._e()]})]:_vm._e(),_c('tr',{staticStyle:{"background":"#eeeeee","font-weight":"bold"}},[_c('td',[_vm._v("SALDO FINAL")]),_vm._l((_vm.formData.saldo),function(objDados,strCompetencia){return _c('td',{key:'saldo-' + strCompetencia,style:('color:' + (objDados.floatValor < 0 ? 'red' : 'green'))},[_vm._v(" "+_vm._s(_vm.$utilidade.floatToMoney(objDados.floatValor))+" ")])})],2)],2)])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }